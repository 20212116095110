<div class="flex justify-starts items-center">
	<ng-container *ngIf="image; else noImage">
		<img
			[src]="image"
			[style.width.px]="size"
			[style.height.px]="size"
			class="rounded-full"
		/>
	</ng-container>
	<ng-template #noImage>
		<div
			[pTooltip]="name"
			tooltipPosition="left"
			[style.width.px]="size"
			[style.height.px]="size"
			[style.fontSize]="getFontSize()"
			class="flex justify-center items-center text-md rounded-full font-bold p-2 border border-2 cursor-pointer"
		>
			{{ getInitials(name) }}
		</div>
	</ng-template>
</div>
